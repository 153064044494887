/** @jsx jsx */
import React from "react";
import { jsx, Box, Flex, Text, Avatar, Image, Link, Paragraph, Container } from "theme-ui";
import { Row, Col, Breadcrumb } from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import Icon from "../components/shared/Icon";
import DefaultIcon from '../images/favicon.png';
import Layout from "../components/Layout";
import GitHubIcon from "../img/avatars/github.png";
import SEO from "../components/seo";

function socialAccount(link, name) {
    if (link.account_type === "TWITTER") {
        return (
            <a target="_blank" href={link.identifier} rel="noreferrer">
                <Flex sx={{justifyContent:'center', alignItems:'center'}}>
                    <Icon icon="twitter" /> <Paragraph color="#FF6310" sx={{marginLeft:'5px'}}>{name}</Paragraph>
                </Flex>
            </a>
        );
    }
    if (link.account_type === "GITHUB") {
        return (
            <a target="_blank" href={link.identifier} rel="noreferrer">
                <Flex sx={{justifyContent:'center', alignItems:'center'}}>
                <Icon icon="github" /> <Text color="#FF6310" sx={{marginLeft:'5px'}}>{name}</Text>
                </Flex>
            </a>
        );
    }
    if (link.account_type === "LINKEDIN") {
        return (
            <a target="_blank" href={link.identifier} rel="noreferrer" sx={{ color: '#FF6310' }}>
                <Flex sx={{justifyContent:'center', alignItems:'center'}}>
                <Icon icon="linkedin" />
                <Text color="#FF6310" sx={{marginLeft:'5px'}}>{name}</Text>
                </Flex>
            </a>
        );
    }
    return <> </>;
}

function DomainExpert({ experts }) {
    return (
        <Row>
            {experts && experts.map((exp, i) => {
                if (i >= 0) {
                    return (
                        <Row
                            sx={{
                                border: "1px solid #D9D9D9",
                                marginTop: "80px",
                                borderRadius: "8px",
                                boxShadow:
                                    "0px 4px 16px rgba(0, 0, 0, 0.04), 0px 8px 40px 8px rgba(0, 0, 0, 0.04)",
                            }}>
                            <React.Fragment key={exp.full_name}>
                                <Col xs={24} md={6} lg={6} sx={{ height: "inherit" }}>
                                    <Image src={exp.picture} sx={{ height: "inherit" }} />
                                </Col>

                                <Col xs={24} lg={18}>
                                    <Flex
                                        sx={{
                                            flexDirection: "column",
                                            gap: "24px",
                                            padding: ["10px", "20px"],
                                        }}
                                    >
                                        <Text sx={{ color: "#262626", fontSize: "14px" }}>
                                            Designed by experts in the field:
                                        </Text>
                                        <Flex sx={{ flexDirection: "column" }}>
                                            <Text
                                                sx={{
                                                    color: "#262626",
                                                    fontSize: "22px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {exp.full_name}
                                            </Text>
                                            <Text sx={{ fontSize: "14px", fontWeight: "600" }}>
                                                {/* Sr Environmental PM{" "} */}
                                                {exp.title}
                                            </Text>
                                        </Flex>
                                        <Text>{exp.bio}</Text>
                                        <Flex sx={{ gap: "20px" }}>
                                            {exp.social_links &&
                                                exp.social_links.map((account) => (
                                                    <Box key={account.identifier}>
                                                        {socialAccount(account, exp.full_name)}
                                                    </Box>
                                                )
                                                )}
                                        </Flex>
                                    </Flex>
                                </Col>
                            </React.Fragment>
                        </Row>
                    );
                }
                return null;
            })}
        </Row>
    );
}

function Package({ pageContext }) {
    const date = new Date(Date.now());
    const { packageData } = pageContext;
    return (
        <Layout key={packageData.description}>
            <SEO
                title={packageData.package_name}
                description={packageData.description}
            />
            <Box>
                <Container className="package-container">
                    <Flex
                        sx={{ flexDirection: "column", paddingBottom: "80px" }}
                    >
                        <Breadcrumb
                            sx={{
                                maxWidth: "1200px",
                                fontSize: "18px",
                                fontStyle: "Source sans pro",
                            }}
                        >
                            <Breadcrumb.Item href="/packages">Packages</Breadcrumb.Item>
                            <Breadcrumb.Item>{packageData.package_name}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Flex
                            sx={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "24px",
                                mt: "24px",
                            }}
                        >
                            <Flex sx={{ alignItems: "center", gap: "24px" }}>
                            {packageData?.icon ? <Image
                                    src={packageData.icon}
                                    sx={{
                                        height: "48px",
                                        width: "auto",
                                    }}
                                    alt="icon"
                                /> : <Image
                                src={DefaultIcon}
                                sx={{
                                    height: "48px",
                                    width: "auto",
                                }}
                                alt="icon"/>}
                                <Text
                                    sx={{
                                        fontSize: ["22px", "28px"],
                                        fontFamily: "Montserrat",
                                        fontWeight: "600",
                                        color: "#2b354f"
                                    }}
                                >
                                    {packageData.package_name}&nbsp;&nbsp;
                                </Text>
                            </Flex>

                            <Text
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    fontFamily: "Source sans pro",
                                    color: "#2b354f"
                                }}
                            >
                                Last updated on{" "}
                                {`${date.toLocaleString("en-US", { month: "short" })
                                    } ${date.toLocaleString("en-US", { day: "2-digit" })
                                    }, ${date.getFullYear()}`}
                            </Text>

                            <Text sx={{ fontSize: "18px", fontFamily: "Source sans pro" }}>
                                {" "}
                                <Paragraph variant="paragraph" ellipsis={{ rows: 3 }}>
                                    <ReactMarkdown className="package-details" remarkPlugins={[remarkGfm, remarkBreaks]}>
                                        {packageData.description}
                                    </ReactMarkdown>
                                </Paragraph>
                            </Text>

                            <Flex sx={{ flexDirection: "column" }}>
                                <Text
                                    sx={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        fontFamily: "Source sans pro",
                                    }}
                                >
                                    Contributors:
                                </Text>
                                <Flex sx={{ marginTop: "6px", gap: "8px", flexWrap:'wrap' }}>
                                    {packageData.contributors && packageData.contributors.length > 0 && packageData.contributors.map((contributor) => (
                                        <Box sx={{width:'30px'}}>
                                        <a
                                            key={contributor.username}
                                            target="_blank"
                                            href={
                                                `https://github.com/${contributor.username === "@great_expectations" ? "great-expectations" : contributor.username.substring(1)}`
                                            }
                                            rel="noreferrer"
                                        >
                                            <Avatar
                                                src={
                                                    `https://github.com/${contributor.username === "@great_expectations" ? "great-expectations" : contributor.username.substring(1)}.png`
                                                }
                                                onError={({ currentTarget }) => {
                                                    const currentElement = currentTarget;
                                                    currentElement.onerror = null;
                                                    currentElement.src = GitHubIcon;
                                                }}
                                                size="34"
                                                shape="circle"
                                                backgroundColor="white"
                                            />
                                        </a>
                                        </Box>
                                    ))}
                                </Flex>
                            </Flex>

                            <Flex sx={{ flexDirection: ["column", null, null, "row"] }}>
                                <Box sx={{minWidth:'130px'}}>

                                <strong className="expec-labels">
                                    Dependencies: &nbsp;&nbsp;{" "}
                                </strong>
                                </Box>
                                <Flex
                                    sx={{
                                        gap: "8px",
                                        flexWrap: "wrap",
                                        mt: ["10px", "0"],
                                    }}
                                >
                                    {packageData.dependencies.length > 0
                                        ? packageData.dependencies.map((value) => (
                                            <React.Fragment key={value.text}>
                                                <Link href={value.link}>
                                                    <Flex sx={{ alignItems: "baseline", gap: "4px" }}>
                                                        <Icon
                                                            icon="external-link"
                                                            fontSize="18px"
                                                            sx={{ color: "#ff6310", ml: ["0", "8px"] }}
                                                        />
                                                        <span
                                                            sx={{ fontSize: "18px", color: "#ff6310" }}
                                                        >
                                                            {value.text}
                                                        </span>
                                                    </Flex>
                                                </Link>
                                            </React.Fragment>
                                        )
                                        )
                                        : <Text sx={{ fontWeight: "500" }}>None</Text>}
                                </Flex>
                            </Flex>

                            {/* <CodeOwner owners={packageData.owners} /> */}
                        </Flex>
                        {/* Need to revisit this once domain expert data is available */}
                        <DomainExpert experts={packageData.domain_experts} />
                        {/* <Search sortingList={PackExpecSortingList} sortingIndex={PackExpecSortingIndex}
                            index={`${process.env.GATSBY_ALGOLIA_PACK_EXPEC_REPLICA_ALPHA_ASC_INDEX}`}
                            packageName={packageData.package_name}
                        /> */}
                    </Flex>
                </Container>
            </Box>
        </Layout>
    );
}

export default Package;
